body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #121212;
}

::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #444;
}

/* Grid Background */
.grid-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(rgba(0, 245, 212, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 245, 212, 0.05) 1px, transparent 1px);
  background-size: 50px 50px;
  pointer-events: none;
  animation: gridMove 15s linear infinite;
  z-index: 0;
}

@keyframes gridMove {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50px);
  }
}

/* Cursor Glow Effect */
.cursor-glow {
  width: 30px;
  height: 30px;
  background: radial-gradient(circle, rgba(0, 245, 212, 0.3) 0%, rgba(0, 245, 212, 0) 70%);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: screen;
  pointer-events: none;
  z-index: 9999;
  filter: blur(5px);
}

/* Glassmorphism */
.glass-effect {
  background: rgba(18, 18, 18, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Gradient Text Animation */
@keyframes gradientText {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Progress Bar Animation */
.progress-bar {
  background: linear-gradient(90deg, #00f5d4, #9d4edd);
  background-size: 200% 200%;
  animation: gradientMove 2s linear infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Hover Effects */
@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .grid-background {
    background-size: 30px 30px;
  }
  
  .cursor-glow {
    display: none;
  }
}
